import React, { useEffect } from 'react'
import Layout from 'src/components/Layout'
import { Flex } from 'theme-ui'

const Page = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location.replace(
        'https://accounts.natura-avon.com/queroserconsultora/?utm_source=avon&utm_medium=site&utm_term=LP_sejarep_redirect&utm_content=perene&utm_campaign=avon_site_LP_sejarep_redirect_perene'
      )
    }
  }, [])

  return (
    <Layout>
      <Flex
        sx={{
          padding: '5rem 0',
          fontSize: '2.5rem',
          fontWeight: 'bold',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        Aguarde um momento, <br /> estamos te levando para o cadastro!
      </Flex>
    </Layout>
  )
}

export default Page
